@tailwind base;
@tailwind components;
@tailwind utilities;

.mapboxgl-ctrl-attrib-inner {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Alegreya+SC:wght@800&display=swap");

h1 {
  font-family: "Alegreya SC", serif;
}

.specialFont {
  font-family: "Alegreya SC", serif;
}
html,
body,
footer {
  background-color: #f8f7fa;
}

select option {
  background-color: white;
}

select option[title]:before {
  content: attr(title);
  display: block;
  padding: 2px 6px;
}
